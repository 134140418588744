
export interface FormData {
  name: string;
  email: string;
  reason: string;
}

export interface ValidationErrors {
  name?: string;
  email?: string;
  reason?: string;
  form?: string;
}

export interface WaitlistEntry {
  id: number;
  created_at: string;
  name: string;
  email: string;
  reason: string | null;
}

export const REASON_MAX_LENGTH = 500;
export const SUBMISSION_COOLDOWN = 60000; // 1 minute in milliseconds
