
import React, { useEffect, useState } from "react";
import { Users } from "lucide-react";
import { useTranslation } from "react-i18next";

interface RegistrationStatsProps {
  signupCount: number;
}

const RegistrationStats: React.FC<RegistrationStatsProps> = ({ signupCount }) => {
  const { t, i18n } = useTranslation();
  const [, forceUpdate] = useState({});
  
  useEffect(() => {
    const handleLanguageChange = () => {
      console.log('RegistrationStats component detected language change');
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    window.addEventListener('languageChangeComplete', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      window.removeEventListener('languageChangeComplete', handleLanguageChange);
    };
  }, []);
  
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  return (
    <div className="mt-12 mb-8">
      <div className="bg-white/50 backdrop-blur-sm rounded-2xl p-6 shadow-lg border border-bubble/20">
        <div className="flex items-center justify-center gap-4">
          <div className="bg-bubble rounded-full p-3">
            <Users className="w-6 h-6 text-black/70" />
          </div>
          <div className="text-left">
            <p className="text-4xl font-bold text-black/80 gradient-text">
              {signupCount.toLocaleString()}+
            </p>
            <p className="text-sm text-black/60 mt-1">
              {t('registration.peopleJoined', 'People have joined the waitlist')}
            </p>
          </div>
        </div>
      </div>

      <p className="text-center text-sm text-black/60 mt-6">
        {t('registration.privacyNote', 'By joining, you agree to receive updates about Bubble Trouble. We respect your privacy and will never share your information.')}
      </p>
    </div>
  );
};

export default RegistrationStats;
