
import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowLeft, Shield, Cookie, Trash2 } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

const Privacy = () => {
  const navigate = useNavigate();

  const handleDataDeletion = async () => {
    const email = prompt("Please enter your email to confirm data deletion:");
    if (!email) return;

    try {
      const { error } = await supabase
        .from('messages')
        .delete()
        .eq('email', email);

      if (error) throw error;
      
      toast.success("Your data has been successfully deleted");
      localStorage.removeItem("registered");
    } catch (error) {
      toast.error("Failed to delete data. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-white py-20">
      <Helmet>
        <title>Privacy Policy - Bubble Trouble</title>
        <meta name="description" content="Read about Bubble Trouble's commitment to protecting your privacy and personal data. Learn how we handle your information and your rights." />
        <meta name="keywords" content="privacy policy, data protection, GDPR, cookie policy, user privacy" />
        <meta property="og:title" content="Privacy Policy - Bubble Trouble" />
        <meta property="og:description" content="Read about Bubble Trouble's commitment to protecting your privacy and personal data. Learn how we handle your information and your rights." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate(-1)}
          className="flex items-center text-black/60 hover:text-black/80 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold gradient-text mb-8">Privacy Policy</h1>
          
          <div className="space-y-8">
            <section className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
              <div className="flex items-center gap-3 mb-4">
                <Shield className="w-6 h-6 text-bubble" />
                <h2 className="text-2xl font-semibold">Data Protection</h2>
              </div>
              <p className="text-black/70 mb-4">
                At Bubble Trouble, we prioritize the protection of your personal information:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-black/70">
                <li>We only collect essential information for service delivery</li>
                <li>Your data is encrypted and stored securely</li>
                <li>We never share your personal information with third parties</li>
                <li>You can request data deletion at any time</li>
              </ul>
              <button
                onClick={handleDataDeletion}
                className="mt-6 flex items-center gap-2 px-4 py-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors"
              >
                <Trash2 className="w-4 h-4" />
                Delete My Data
              </button>
            </section>

            <section className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
              <div className="flex items-center gap-3 mb-4">
                <Cookie className="w-6 h-6 text-bubble" />
                <h2 className="text-2xl font-semibold">Cookie Policy</h2>
              </div>
              <p className="text-black/70 mb-4">
                We use cookies to enhance your browsing experience:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-black/70">
                <li>
                  <strong>Essential Cookies:</strong> Required for basic website functionality
                </li>
                <li>
                  <strong>Preference Cookies:</strong> Remember your settings and choices
                </li>
                <li>
                  <strong>Analytics Cookies:</strong> Help us understand how you use our site
                </li>
              </ul>
              <p className="mt-4 text-black/70">
                You can manage your cookie preferences through your browser settings.
              </p>
            </section>

            <section className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
              <h2 className="text-2xl font-semibold mb-4">GDPR Compliance</h2>
              <p className="text-black/70 mb-4">
                Under GDPR, you have several rights regarding your personal data:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-black/70">
                <li>Right to access your personal data</li>
                <li>Right to rectification of inaccurate data</li>
                <li>Right to erasure ("right to be forgotten")</li>
                <li>Right to restrict processing</li>
                <li>Right to data portability</li>
                <li>Right to object to processing</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
