
// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = 'https://fmsijphhzututcmzlhfr.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZtc2lqcGhoenV0dXRjbXpsaGZyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk4NjE1NzYsImV4cCI6MjA1NTQzNzU3Nn0.e6MyIJ2j5ehDnnI-jAGY5toj7m6nqMVjAfpiHeOpN_o';

if (!SUPABASE_ANON_KEY) {
  console.error('Supabase anon key is missing. Please check your environment variables.');
}

export const supabase = createClient<Database>(
  SUPABASE_URL, 
  SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true
    },
  }
);
