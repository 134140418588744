
import { Helmet } from "react-helmet-async";
import Hero from "@/components/Hero";
import Features from "@/components/Features";
import Registration from "@/components/Registration";
import FAQ from "@/components/FAQ";
import Footer from "@/components/Footer";
import SkipLink from "@/components/SkipLink";
import { useState, useEffect } from "react";
import { ArrowUp, Menu, X } from "lucide-react";

const Index = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("hero");
  const canonicalUrl = window.location.origin;
  const title = "Bubble Trouble - Authentic Social Interactions";
  const description = "Join Bubble Trouble for authentic and ephemeral social interactions. Create meaningful connections without the pressure of a permanent digital footprint.";
  const keywords = "social media, ephemeral content, privacy first, authentic interactions, bubble trouble";
  const logoUrl = "/lovable-uploads/3630a64e-6598-4188-85c2-1c85d00e59a6.png";

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 500);

      // Update active section based on scroll position
      const sections = ["hero", "features", "registration-section", "faq"];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setMobileMenuOpen(false);
    }
  };

  const navItems = [
    { id: "hero", label: "Home" },
    { id: "features", label: "Features" },
    { id: "registration-section", label: "Join Beta" },
    { id: "faq", label: "FAQ" },
  ];

  return (
    <div className="min-h-screen bg-white flex flex-col" role="document">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={logoUrl} />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={logoUrl} />
        
        {/* Additional SEO */}
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="7 days" />
        <meta name="author" content="Bubble Trouble" />
        
        {/* Theme Color for Mobile Browsers */}
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        
        {/* Viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      </Helmet>

      <SkipLink />

      {/* Sticky Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md shadow-sm transition-all duration-300">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <img
              src={logoUrl}
              alt="Bubble Trouble"
              className="h-8 w-8 object-contain"
            />
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex space-x-8">
              {navItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => scrollToSection(item.id)}
                  className={`px-3 py-2 text-sm font-medium transition-all duration-200
                    ${activeSection === item.id 
                      ? "text-bubble-dark border-b-2 border-bubble" 
                      : "text-black/60 hover:text-bubble-dark"
                    }`}
                >
                  {item.label}
                </button>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="md:hidden p-2 rounded-lg hover:bg-bubble/10 active:bg-bubble/20 transition-colors"
              aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
            >
              {mobileMenuOpen ? 
                <X className="w-6 h-6 text-black/80" /> : 
                <Menu className="w-6 h-6 text-black/80" />
              }
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation Overlay */}
      <div
        className={`fixed inset-0 bg-white/95 backdrop-blur-sm z-40 transition-all duration-300 md:hidden ${
          mobileMenuOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
      >
        <nav className="h-full flex items-center justify-center pt-16" aria-label="Mobile navigation">
          <ul className="space-y-6 text-center">
            {navItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => scrollToSection(item.id)}
                  className={`text-xl font-medium py-2 px-4 rounded-lg transition-all duration-200
                    ${activeSection === item.id
                      ? "text-bubble-dark bg-bubble/10"
                      : "text-black/80 hover:bg-bubble/10"
                    }`}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <main id="main-content" className="flex-grow pt-16" role="main" tabIndex={-1}>
        <section id="hero" aria-label="Hero">
          <Hero onGetStarted={() => scrollToSection('registration-section')} />
        </section>

        <section id="features" aria-label="Features" className="scroll-mt-20">
          <Features />
        </section>

        <section id="registration-section" aria-label="Registration" className="scroll-mt-20">
          <Registration />
        </section>

        <section id="faq" aria-label="Frequently Asked Questions" className="scroll-mt-20">
          <FAQ />
        </section>
      </main>
      
      <Footer />

      {/* Back to Top Button - Enhanced */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-4 sm:right-8 p-3 bg-bubble hover:bg-bubble-dark active:scale-95 text-black/80 rounded-full shadow-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-bubble-dark focus:ring-offset-2 ${
          showBackToTop ? "translate-y-0 opacity-100" : "translate-y-16 opacity-0"
        }`}
        aria-label="Back to top"
      >
        <ArrowUp className="w-5 h-5" />
      </button>
    </div>
  );
};

export default Index;
