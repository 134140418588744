
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import i18n from './i18n.ts' // Import i18n configuration

// Create root once
const rootElement = document.getElementById("root")!
const root = createRoot(rootElement)

// Render function that doesn't unmount/remount
const renderApp = () => {
  console.log('Rendering app with language:', i18n.language)
  root.render(<App />)
}

// Initialize the app once i18n is ready
const initApp = () => {
  // Render the initial app
  renderApp()
  
  // Set up language change listener that doesn't unmount the app
  window.addEventListener('languageChangeComplete', () => {
    console.log('Language change detected, updating UI')
    // Simply trigger a re-render without unmounting
    renderApp()
  })
}

// Check if i18n is already initialized
if (i18n.isInitialized) {
  console.log('i18n already initialized, rendering app immediately')
  initApp()
} else {
  console.log('Waiting for i18n to initialize...')
  i18n.on('initialized', () => {
    console.log('i18n initialized, now rendering app')
    initApp()
  })
}
