
import React, { useState, useEffect } from "react";
import { Mail, Github, X, Instagram, Facebook } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const Footer = () => {
  const { t, i18n } = useTranslation();
  // Force re-render when language changes
  const [, forceUpdate] = useState({});
  
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleJoinWaitlist = () => {
    const registrationSection = document.getElementById("registration-section");
    if (registrationSection) {
      registrationSection.scrollIntoView({ behavior: "smooth" });
    } else {
      const homeSection = document.querySelector("main");
      if (homeSection) {
        homeSection.scrollIntoView({ behavior: "smooth" });
        const getStartedBtn = document.querySelector('button');
        if (getStartedBtn) {
          getStartedBtn.click();
        }
      }
    }
  };
  
  useEffect(() => {
    // This function will be called when the language changes
    const handleLanguageChange = () => {
      console.log('Footer component detected language change:', i18n.language);
      // Force component to re-render
      forceUpdate({});
    };
    
    // Listen to language change events
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    
    // Set up listener for i18next initialization
    if (!i18n.isInitialized) {
      const initialized = () => {
        console.log('i18next initialized in Footer component');
        forceUpdate({});
      };
      i18n.on('initialized', initialized);
      return () => {
        i18n.off('initialized', initialized);
        window.removeEventListener('languageChanged', handleLanguageChange);
        document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      };
    }
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
    };
  }, [i18n]);

  // Add direct listener for i18n language change
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  return (
    <footer className="bg-white py-12 border-t border-gray-100">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-12">
          <div className="space-y-4">
            <h3 className="font-semibold text-lg gradient-text">Bubble Trouble</h3>
            <p className="text-sm text-black/60">
              {t('app.tagline')}
            </p>
            <div className="pt-2">
              <LanguageSwitcher />
            </div>
          </div>
          
          <div className="space-y-4">
            <h4 className="font-semibold text-sm">{t('footer.features')}</h4>
            <ul className="space-y-3">
              <li>
                <button 
                  onClick={() => scrollToSection('features')} 
                  className="text-sm text-black/60 hover:text-black/80 transition-colors"
                >
                  {t('footer.ourFeatures')}
                </button>
              </li>
              <li>
                <button 
                  onClick={() => scrollToSection('faq')} 
                  className="text-sm text-black/60 hover:text-black/80 transition-colors"
                >
                  {t('nav.faq')}
                </button>
              </li>
              <li>
                <button 
                  onClick={handleJoinWaitlist} 
                  className="text-sm text-black/60 hover:text-black/80 transition-colors"
                >
                  {t('nav.joinWaitlist')}
                </button>
              </li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <h4 className="font-semibold text-sm">{t('footer.company')}</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-sm text-black/60 hover:text-black/80 transition-colors">
                  {t('nav.about')}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-sm text-black/60 hover:text-black/80 transition-colors">
                  {t('nav.privacy')}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-sm text-black/60 hover:text-black/80 transition-colors">
                  {t('nav.terms')}
                </Link>
              </li>
            </ul>
          </div>
          
          <div className="space-y-4">
            <h4 className="font-semibold text-sm">{t('footer.connect')}</h4>
            <div className="flex space-x-4">
              <a 
                href="mailto:bubbletroubleapp@gmail.com" 
                className="text-black/60 hover:text-black/80 transition-colors p-2"
                aria-label="Email us"
              >
                <Mail className="w-5 h-5" />
              </a>
              <a 
                href="https://github.com/jordantolin/BubbleTrouble" 
                className="text-black/60 hover:text-black/80 transition-colors p-2"
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Visit our GitHub"
              >
                <Github className="w-5 h-5" />
              </a>
              <a 
                href="https://x.com/bubbletroubleap" 
                className="text-black/60 hover:text-black/80 transition-colors p-2"
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Follow us on X (Twitter)"
              >
                <X className="w-5 h-5" />
              </a>
              <a 
                href="https://www.instagram.com/bubbletroubleapp/" 
                className="text-black/60 hover:text-black/80 transition-colors p-2"
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Follow us on Instagram"
              >
                <Instagram className="w-5 h-5" />
              </a>
              <a 
                href="https://www.facebook.com/people/Bubble-Trouble/61573030246357/" 
                className="text-black/60 hover:text-black/80 transition-colors p-2"
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Follow us on Facebook"
              >
                <Facebook className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
        
        <div className="pt-8 border-t border-gray-100">
          <p className="text-center text-sm text-black/60">
            © {new Date().getFullYear()} Bubble Trouble. {t('footer.allRightsReserved')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
