import React, { useState, useEffect } from "react";
import { MessageCircle, Shield, Clock, Play, Pause } from "lucide-react";
import { useTranslation } from "react-i18next";
import ScrollReveal from "./ScrollReveal";

type HeroProps = {
  onGetStarted: () => void;
};

const Hero = ({ onGetStarted }: HeroProps) => {
  const { t, i18n } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  // Force re-render when language changes
  const [, forceUpdate] = useState({});

  useEffect(() => {
    const handleLanguageChange = () => {
      console.log('Hero component detected language change');
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    window.addEventListener('languageChangeComplete', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      window.removeEventListener('languageChangeComplete', handleLanguageChange);
    };
  }, []);

  // Add direct listener for i18n language change
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  const toggleVideo = () => {
    const video = document.getElementById('intro-video') as HTMLVideoElement;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <section className="min-h-[calc(100vh-4rem)] flex flex-col items-center justify-center relative overflow-hidden bg-gradient-to-b from-bubble-soft via-bubble-soft to-white py-6 md:py-16">
      {/* Animated background bubbles */}
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className="absolute transition-all"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `float ${12 + i * 4}s ease infinite`,
            animationDelay: `${i * 2}s`,
          }}
        >
          <div
            className="w-12 h-12 md:w-24 md:h-24 rounded-full bg-bubble opacity-5 transition-all duration-1000"
            style={{
              animation: `ripple ${6 + i * 2}s ease-in-out infinite`,
            }}
          />
        </div>
      ))}

      <div className="container mx-auto px-4 text-center z-10">
        {/* Logo and Title */}
        <ScrollReveal variant="fade-down">
          <div className="mb-6 md:mb-8 animate-fade-up relative group">
            <div className="absolute inset-0 bg-bubble-light/30 rounded-full filter blur-xl group-hover:blur-2xl transform group-hover:scale-110 transition-all duration-700"></div>
            <img
              src="/lovable-uploads/3630a64e-6598-4188-85c2-1c85d00e59a6.png"
              alt="Bubble Trouble"
              className="w-16 h-16 md:w-24 md:h-24 mx-auto object-contain relative z-10 transform transition-all duration-500 
                       hover:scale-105 hover:rotate-3 active:scale-95
                       drop-shadow-[0_0_15px_rgba(255,215,0,0.3)]
                       hover:drop-shadow-[0_0_25px_rgba(255,215,0,0.5)]"
            />
          </div>
        </ScrollReveal>

        <ScrollReveal variant="fade-up" delay={100}>
          <h1 
            className="text-2xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 animate-fade-up relative tracking-tight text-[#FFD700]"
            style={{ 
              animationDelay: "0.3s",
              fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif"
            }}
          >
            <span className="relative inline-block transform transition-all duration-300 hover:scale-102.5">
              Bubble Trouble
            </span>
          </h1>
        </ScrollReveal>

        <ScrollReveal variant="fade-up" delay={200}>
          <span className="inline-block px-3 py-1 md:px-4 md:py-2 rounded-full bg-bubble-light/80 text-black/70 text-xs md:text-sm font-medium mb-3 md:mb-6 animate-fade-up hover-scale backdrop-blur-sm">
            {t('hero.betaAccess', 'Limited-Time Beta Access')}
          </span>
        </ScrollReveal>

        <ScrollReveal variant="fade-up" delay={300}>
          <h2
            className="text-xl md:text-4xl lg:text-5xl font-bold mb-3 md:mb-6 gradient-text animate-fade-up hover-scale tracking-tight"
            style={{ animationDelay: "0.2s" }}
          >
            {t('hero.tagline', 'Social Media Without the Pressure')}
          </h2>
        </ScrollReveal>

        <ScrollReveal variant="fade-up" delay={400}>
          <p
            className="text-base md:text-xl lg:text-2xl text-black/80 mb-4 md:mb-6 max-w-2xl mx-auto animate-fade-up"
            style={{ animationDelay: "0.4s" }}
          >
            {t('hero.description', 'Bubble Trouble reimagines social connections through ephemeral conversations that feel natural and authentic messages that shine brightly, then gracefully fade away.')}
          </p>
        </ScrollReveal>
        
        <ScrollReveal variant="fade-up" delay={500}>
          <p
            className="text-sm md:text-lg lg:text-xl text-black/70 mb-6 md:mb-10 max-w-2xl mx-auto animate-fade-up"
            style={{ animationDelay: "0.5s" }}
          >
            {t('hero.subtitle', 'Join our pioneering community of creators and free thinkers who are shaping a more human-centered social experience, free from permanent digital footprints.')}
          </p>
        </ScrollReveal>

        {/* Video Section */}
        <ScrollReveal variant="zoom-in" delay={200}>
          <div className="max-w-4xl mx-auto my-10 md:my-16 animate-fade-up">
            <h3 className="text-lg md:text-2xl font-semibold mb-4 md:mb-6 text-black/80">
              {t('hero.watchVideo', 'Watch How Bubble Trouble Works')}
            </h3>
            
            <div className="relative group rounded-xl overflow-hidden shadow-xl transition-all duration-300 hover:shadow-2xl">
              <div className="relative aspect-video bg-black/10 rounded-xl overflow-hidden">
                <video 
                  id="intro-video"
                  poster="/lovable-uploads/3630a64e-6598-4188-85c2-1c85d00e59a6.png"
                  className="w-full h-full object-cover"
                  playsInline
                  controls={false}
                  onEnded={() => setIsPlaying(false)}
                  onClick={toggleVideo}
                >
                  <source src="/lovable-uploads/intro-video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                
                {/* Play/Pause Button Overlay - Now hidden when playing */}
                <button 
                  onClick={toggleVideo}
                  className={`absolute inset-0 flex items-center justify-center bg-black/30 group-hover:bg-black/40 transition-all duration-300 ${
                    isPlaying ? 'opacity-0 pointer-events-none' : 'opacity-100'
                  }`}
                  aria-label={isPlaying ? "Pause video" : "Play video"}
                >
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-bubble flex items-center justify-center transform transition-all duration-300 hover:scale-110 hover:bg-bubble-dark">
                    {isPlaying ? (
                      <Pause className="w-6 h-6 md:w-8 md:h-8 text-black/80" />
                    ) : (
                      <Play className="w-6 h-6 md:w-8 md:h-8 text-black/80 ml-1" />
                    )}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </ScrollReveal>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 max-w-4xl mx-auto mb-6 md:mb-8">
          <ScrollReveal variant="fade-left" delay={200}>
            <div
              className="flex flex-col items-center p-3 md:p-6 rounded-2xl glass-card animate-fade-up hover-scale shadow-lg"
              style={{ animationDelay: "0.6s" }}
            >
              <MessageCircle className="w-6 h-6 md:w-10 md:h-10 text-bubble-dark mb-2 md:mb-4" />
              <h3 className="text-sm md:text-lg font-semibold mb-1 md:mb-2 text-black/80">{t('hero.features.bubbles.title', 'Dynamic Bubbles')}</h3>
              <p className="text-xs md:text-sm text-black/60">
                {t('hero.features.bubbles.description', 'Experience conversations that evolve naturally and disappear over time, like real-life interactions')}
              </p>
            </div>
          </ScrollReveal>

          <ScrollReveal variant="fade-up" delay={400}>
            <div
              className="flex flex-col items-center p-3 md:p-6 rounded-2xl glass-card animate-fade-up hover-scale shadow-lg"
              style={{ animationDelay: "0.8s" }}
            >
              <Shield className="w-6 h-6 md:w-10 md:h-10 text-bubble-dark mb-2 md:mb-4" />
              <h3 className="text-sm md:text-lg font-semibold mb-1 md:mb-2 text-black/80">{t('hero.features.freedom.title', 'Freedom to Express')}</h3>
              <p className="text-xs md:text-sm text-black/60">
                {t('hero.features.freedom.description', 'Share your authentic self without worrying about a permanent digital record following you')}
              </p>
            </div>
          </ScrollReveal>

          <ScrollReveal variant="fade-right" delay={200}>
            <div
              className="flex flex-col items-center p-3 md:p-6 rounded-2xl glass-card animate-fade-up hover-scale shadow-lg"
              style={{ animationDelay: "1s" }}
            >
              <Clock className="w-6 h-6 md:w-10 md:h-10 text-bubble-dark mb-2 md:mb-4" />
              <h3 className="text-sm md:text-lg font-semibold mb-1 md:mb-2 text-black/80">{t('hero.features.realTime.title', 'Real-Time Magic')}</h3>
              <p className="text-xs md:text-sm text-black/60">
                {t('hero.features.realTime.description', 'Watch conversations bloom and transform in the moment, creating truly engaging experiences')}
              </p>
            </div>
          </ScrollReveal>
        </div>

        <ScrollReveal variant="fade-up" delay={600}>
          <button
            onClick={onGetStarted}
            className="mt-4 px-6 py-3 md:px-8 md:py-4 bg-bubble hover:bg-bubble-dark text-black/80 rounded-full font-medium transition-all duration-300 animate-fade-up hover-scale shadow-lg text-base md:text-lg"
            style={{ animationDelay: "1.2s" }}
          >
            {t('hero.joinBeta', 'Join the Exclusive Beta')}
          </button>
          
          <p className="text-xs md:text-sm text-black/60 mt-4 animate-fade-up">
            {t('hero.limitedSpots', 'Limited spots available — Be among the first to experience a new social reality.')}
          </p>
        </ScrollReveal>
      </div>
    </section>
  );
};

export default Hero;
