
import React from "react";
import { Mail } from "lucide-react";
import { FormData, REASON_MAX_LENGTH } from "./types";
import { Link } from "react-router-dom";
import { toast } from "sonner";

interface RegistrationFormProps {
  formData: FormData;
  errors: Partial<FormData>;
  isLoading?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  formData,
  errors,
  isLoading = false,
  handleChange,
  handleSubmit,
}) => {
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      toast.error("Please fix the form errors before submitting");
      return;
    }
    await handleSubmit(e);
  };

  return (
    <form onSubmit={handleFormSubmit} className="space-y-6">
      <div className="glass-card p-8 rounded-2xl animate-fade-up">
        <div className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-black/70 mb-1"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.name ? 'border-red-500 ring-1 ring-red-500' : 'border-bubble-light'
              } bg-white focus:outline-none focus:ring-2 focus:ring-bubble transition-all text-black`}
              placeholder="Enter your name"
              disabled={isLoading}
              aria-invalid={errors.name ? "true" : "false"}
              aria-describedby={errors.name ? "name-error" : undefined}
            />
            {errors.name && (
              <p id="name-error" className="text-red-500 text-xs mt-1 ml-1">{errors.name}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-black/70 mb-1"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.email ? 'border-red-500 ring-1 ring-red-500' : 'border-bubble-light'
              } bg-white focus:outline-none focus:ring-2 focus:ring-bubble transition-all text-black`}
              placeholder="you@example.com"
              disabled={isLoading}
              aria-invalid={errors.email ? "true" : "false"}
              aria-describedby={errors.email ? "email-error" : undefined}
            />
            {errors.email && (
              <p id="email-error" className="text-red-500 text-xs mt-1 ml-1">{errors.email}</p>
            )}
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label
                htmlFor="reason"
                className="block text-sm font-medium text-black/70"
              >
                Why are you interested?
              </label>
              <span className={`text-xs ${
                formData.reason.length >= REASON_MAX_LENGTH ? 'text-red-500' : 'text-black/60'
              }`}>
                {formData.reason.length}/{REASON_MAX_LENGTH}
              </span>
            </div>
            <textarea
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              maxLength={REASON_MAX_LENGTH}
              rows={3}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.reason ? 'border-red-500 ring-1 ring-red-500' : 'border-bubble-light'
              } bg-white focus:outline-none focus:ring-2 focus:ring-bubble transition-all resize-none text-black`}
              placeholder="Tell us what excites you about Bubble Trouble"
              disabled={isLoading}
              aria-invalid={errors.reason ? "true" : "false"}
              aria-describedby={errors.reason ? "reason-error" : undefined}
            />
            {errors.reason && (
              <p id="reason-error" className="text-red-500 text-xs mt-1 ml-1">{errors.reason}</p>
            )}
          </div>

          <div className="text-sm text-black/60">
            <p>
              By joining, you agree to our{" "}
              <Link to="/terms" className="text-bubble hover:underline">Terms of Service</Link>
              {" "}and{" "}
              <Link to="/privacy" className="text-bubble hover:underline">Privacy Policy</Link>. 
              We'll handle your data according to our privacy policy and send you occasional 
              updates about Bubble Trouble.
            </p>
          </div>
        </div>

        <button
          type="submit"
          disabled={isLoading || Object.keys(errors).length > 0}
          className={`w-full mt-6 px-8 py-3 bg-bubble hover:bg-bubble-dark text-black/80 rounded-full font-medium transition-all duration-300 hover-scale shadow-lg flex items-center justify-center gap-2 ${
            isLoading || Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          aria-busy={isLoading}
        >
          <Mail className="w-4 h-4" />
          {isLoading ? 'Joining...' : 'Join the Waitlist'}
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
