
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';
import { toast } from "sonner";

const languages = [
  { code: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
];

const FloatingLanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [currentLang, setCurrentLang] = useState('');
  
  // Set current language once i18n is initialized
  useEffect(() => {
    if (i18n.isInitialized) {
      setCurrentLang(i18n.language.split('-')[0]); // Use base language code (en-US -> en)
    }
  }, [i18n.isInitialized, i18n.language]);
  
  useEffect(() => {
    // Delay showing the component until after initial page load
    const timer = setTimeout(() => {
      setIsMounted(true);
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);
  
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  
  const changeLanguage = async (langCode: string) => {
    if (isChanging || langCode === currentLang) {
      setIsExpanded(false);
      return;
    }

    setIsChanging(true);
    
    try {
      console.log('FloatingSelector: Changing language to:', langCode);
      
      // Change the language in i18next
      await i18n.changeLanguage(langCode);
      
      setCurrentLang(langCode);
      
      // Show success toast
      const langName = languages.find(lang => lang.code === langCode)?.name || langCode;
      toast.success(`Language changed to ${langName}`);
    } catch (err) {
      console.error('Language change error:', err);
      toast.error("Failed to change language. Please try again.");
    } finally {
      setIsChanging(false);
      setIsExpanded(false);
    }
  };
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.target || !(event.target as Element).closest('.language-selector-container')) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  if (!isMounted) return null;
  
  // Find current language object
  const currentLanguage = languages.find(lang => lang.code === currentLang) || languages[0];
  
  return (
    <div className="language-selector-container fixed left-4 bottom-20 z-40 transition-all duration-300">
      <div className="relative">
        <button
          onClick={toggleExpand}
          className="w-10 h-10 rounded-full bg-bubble flex items-center justify-center shadow-lg hover:bg-bubble-dark transition-colors"
          aria-label="Select language"
          aria-expanded={isExpanded}
          disabled={isChanging}
        >
          {currentLanguage.flag ? (
            <span className="text-lg">{currentLanguage.flag}</span>
          ) : (
            <Globe className="w-5 h-5 text-black/80" />
          )}
        </button>
        
        {isExpanded && (
          <div className="absolute bottom-14 left-0 bg-white rounded-lg shadow-xl py-2 w-40 border border-gray-100 animate-fade-up">
            {languages.map((language) => (
              <button
                key={language.code}
                onClick={() => changeLanguage(language.code)}
                className={`w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-bubble/10 transition-colors ${
                  currentLang === language.code ? 'bg-bubble/10 font-medium' : ''
                } ${isChanging ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isChanging}
              >
                <span>{language.flag}</span>
                <span className="text-sm">{language.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingLanguageSelector;
