
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <Helmet>
        <title>404 - Page Not Found | Bubble Trouble</title>
        <meta name="description" content="The page you're looking for cannot be found. Return to Bubble Trouble's homepage." />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      
      <div className="text-center px-4">
        <h1 className="text-4xl font-bold mb-4 gradient-text">404 - Page Not Found</h1>
        <p className="text-lg text-black/60 mb-8">Oops! The page you're looking for doesn't exist.</p>
        <Link
          to="/"
          className="px-6 py-3 bg-bubble hover:bg-bubble-dark text-black/80 rounded-full font-medium transition-all duration-300"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
