
import React, { useEffect, useState } from "react";
import RegistrationForm from "./registration/RegistrationForm";
import SuccessMessage from "./registration/SuccessMessage";
import RegistrationStats from "./registration/RegistrationStats";
import { useRegistration } from "./registration/useRegistration";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import ScrollReveal from "./ScrollReveal";

const Registration: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [, forceUpdate] = useState({});
  
  useEffect(() => {
    const handleLanguageChange = () => {
      console.log('Registration component detected language change');
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    window.addEventListener('languageChangeComplete', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      window.removeEventListener('languageChangeComplete', handleLanguageChange);
    };
  }, []);
  
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  const {
    formData,
    errors,
    signupCount,
    registered,
    submitted,
    isLoading,
    handleChange,
    handleSubmit,
  } = useRegistration();

  if (submitted || registered) {
    return <SuccessMessage />;
  }

  return (
    <section className="py-12 md:py-20 bg-gradient-to-b from-bubble-soft via-white to-bubble-soft">
      <div className="container mx-auto px-4">
        <ScrollReveal variant="fade-up">
          <div className="max-w-xl mx-auto text-center mb-8 md:mb-12">
            <span className="inline-block px-3 py-1.5 md:px-4 md:py-2 rounded-full bg-bubble-light text-black/70 text-xs md:text-sm font-medium mb-3 md:mb-4 hover-scale">
              {t('registration.earlyAccess', 'Early Access')}
            </span>
            <h2 className="text-2xl md:text-4xl font-bold mb-3 md:mb-4 gradient-text hover-scale">
              {t('registration.joinWaitlist', 'Join the Waitlist')}
            </h2>
            <p className="text-base md:text-xl text-black/60">
              {t('registration.description', 'Be among the first to discover authentic ephemeral social interactions.')}
            </p>
          </div>
        </ScrollReveal>

        <ScrollReveal variant="fade-up" delay={200}>
          <div className="max-w-md mx-auto relative">
            {isLoading && (
              <div className="absolute inset-0 bg-white/50 backdrop-blur-sm z-10 flex items-center justify-center">
                <div className="flex items-center gap-2 px-4 py-2 rounded-full bg-bubble shadow-lg">
                  <Loader2 className="w-5 h-5 animate-spin text-black/70" />
                  <span className="text-sm font-medium text-black/70">{t('registration.registering', 'Registering...')}</span>
                </div>
              </div>
            )}
            <RegistrationForm
              formData={formData}
              errors={errors}
              isLoading={isLoading}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <RegistrationStats signupCount={signupCount} />
          </div>
        </ScrollReveal>
      </div>
    </section>
  );
};

export default Registration;
