
import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowLeft } from "lucide-react";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white py-20">
      <Helmet>
        <title>Terms of Service - Bubble Trouble</title>
        <meta name="description" content="Read Bubble Trouble's terms of service. Understand our usage guidelines, content policy, and user responsibilities." />
        <meta name="keywords" content="terms of service, usage guidelines, content policy, user agreement" />
        <meta property="og:title" content="Terms of Service - Bubble Trouble" />
        <meta property="og:description" content="Read Bubble Trouble's terms of service. Understand our usage guidelines, content policy, and user responsibilities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate(-1)}
          className="flex items-center text-black/60 hover:text-black/80 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold gradient-text mb-8">Terms of Service</h1>
          
          <div className="prose prose-lg">
            <p className="text-black/70 mb-6">
              By using Bubble Trouble, you agree to these terms of service. Please read 
              them carefully before using our platform.
            </p>

            <h2 className="text-2xl font-semibold mb-4 mt-8">Usage Guidelines</h2>
            <ul className="list-disc pl-6 space-y-3 text-black/70">
              <li>Respect other users' privacy</li>
              <li>No harassment or harmful content</li>
              <li>No illegal activities</li>
              <li>No spamming or automated usage</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4 mt-8">Content Policy</h2>
            <p className="text-black/70 mb-6">
              All content shared on Bubble Trouble must comply with our community 
              guidelines and applicable laws. Content is automatically deleted after 
              its designated time period.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
