
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe, Check, ChevronDown } from 'lucide-react';
import { toast } from "sonner";

const languages = [
  { code: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
];

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  // Update current language when i18n.language changes
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  
  const changeLanguage = async (langCode: string) => {
    if (isChanging || langCode === currentLang) {
      setIsOpen(false);
      return;
    }

    setIsChanging(true);
    
    try {
      console.log('Changing language to:', langCode);
      
      // Change the language in i18next - this will trigger the languageChanged event
      await i18n.changeLanguage(langCode);
      
      setCurrentLang(langCode);
      
      // Show success toast
      toast.success(`Language changed to ${languages.find(lang => lang.code === langCode)?.name}`);
    } catch (err) {
      console.error('Language change error:', err);
      toast.error("Failed to change language. Please try again.");
    } finally {
      setIsChanging(false);
      setIsOpen(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Currently selected language
  const currentLanguage = languages.find(lang => lang.code === currentLang) || languages[0];

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-bubble/10 transition-colors"
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Select language"
        disabled={isChanging}
      >
        <Globe className="w-4 h-4 text-black/70" />
        <span className="text-sm font-medium text-black/80">{currentLanguage.flag} {currentLanguage.name}</span>
        <ChevronDown className={`w-4 h-4 text-black/60 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50 border border-gray-100 animate-fade-up">
          {languages.map((language) => (
            <button
              key={language.code}
              onClick={() => changeLanguage(language.code)}
              className={`w-full text-left px-4 py-2.5 flex items-center space-x-3 hover:bg-bubble/10 transition-colors ${
                currentLang === language.code ? 'bg-bubble/10 font-medium' : ''
              } ${isChanging ? 'opacity-50 cursor-not-allowed' : ''}`}
              aria-label={`Change language to ${language.name}`}
              disabled={isChanging}
            >
              <span className="text-lg">{language.flag}</span>
              <span className="text-sm text-black/80">{language.name}</span>
              {currentLang === language.code && (
                <Check className="w-4 h-4 text-bubble-dark ml-auto" />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
