
import React, { useState } from "react";
import { Check, Share2, Twitter, Facebook, Linkedin, Send, Phone } from "lucide-react";
import { toast } from "sonner";

const SuccessMessage: React.FC = () => {
  const [showShare, setShowShare] = useState(false);
  
  const shareText = "I just joined the waitlist for Bubble Trouble - the future of authentic social interactions! 🚀";
  const shareUrl = window.location.href;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Bubble Trouble',
          text: shareText,
          url: shareUrl,
        });
      } catch (err) {
        setShowShare(true);
      }
    } else {
      setShowShare(true);
    }
  };

  const handleSocialShare = (platform: string) => {
    let url = '';
    switch (platform) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        break;
      case 'whatsapp':
        url = `https://wa.me/?text=${encodeURIComponent(shareText + ' ' + shareUrl)}`;
        break;
      case 'telegram':
        url = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'tiktok':
        url = `https://www.tiktok.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
    }
    window.open(url, '_blank');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.success("Link copied to clipboard!");
      setShowShare(false);
    });
  };

  return (
    <section className="py-20 bg-gradient-to-b from-bubble-soft via-white to-bubble-soft">
      <div className="container mx-auto px-4">
        <div className="max-w-xl mx-auto text-center mb-12">
          <div className="flex justify-center mb-6 animate-fade-up">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
              <Check className="w-8 h-8 text-green-600" />
            </div>
          </div>
          <h2 className="text-4xl font-bold mb-4 gradient-text animate-fade-up">
            Welcome to the Community!
          </h2>
          <p className="text-xl text-black/60 mb-8 animate-fade-up">
            Thank you for joining the Bubble Trouble waitlist.
            We'll contact you soon with more details.
          </p>
          
          <div className="relative animate-fade-up">
            <button
              onClick={handleShare}
              className="px-6 py-3 bg-bubble hover:bg-bubble-dark text-black/80 rounded-full font-medium transition-all duration-300 flex items-center gap-2 mx-auto hover-scale"
            >
              <Share2 className="w-4 h-4" />
              Share with Friends
            </button>

            {showShare && (
              <div className="fixed inset-x-0 bottom-4 mx-4 md:absolute md:bottom-auto md:top-[calc(100%+0.5rem)] md:left-1/2 md:-translate-x-1/2 z-50 animate-fade-up">
                <div className="relative backdrop-blur-lg bg-gradient-to-b from-bubble-soft/90 to-white/90 rounded-2xl shadow-lg border border-bubble p-6 max-w-[320px] mx-auto">
                  {/* Arrow - visible only on desktop */}
                  <div className="hidden md:block absolute -top-2 left-1/2 -translate-x-1/2 w-4 h-4 rotate-45 bg-bubble-soft/90 border-l border-t border-bubble"></div>
                  
                  <p className="text-sm text-black/70 mb-4 font-medium">
                    Share via
                  </p>
                  
                  <div className="grid grid-cols-3 gap-3 mb-4">
                    <button
                      onClick={() => handleSocialShare('twitter')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on Twitter"
                    >
                      <Twitter className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                    <button
                      onClick={() => handleSocialShare('facebook')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on Facebook"
                    >
                      <Facebook className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                    <button
                      onClick={() => handleSocialShare('linkedin')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on LinkedIn"
                    >
                      <Linkedin className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                    <button
                      onClick={() => handleSocialShare('telegram')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on Telegram"
                    >
                      <Send className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                    <button
                      onClick={() => handleSocialShare('whatsapp')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on WhatsApp"
                    >
                      <Phone className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                    <button
                      onClick={() => handleSocialShare('tiktok')}
                      className="p-3 hover:bg-bubble-light rounded-full transition-all duration-300 hover-scale group"
                      aria-label="Share on TikTok"
                    >
                      <Share2 className="w-5 h-5 text-black/60 group-hover:text-black/80" />
                    </button>
                  </div>
                  
                  <div className="relative">
                    <button
                      onClick={copyToClipboard}
                      className="w-full px-4 py-2.5 bg-bubble hover:bg-bubble-dark text-black/80 rounded-xl text-sm transition-all duration-300 font-medium hover-scale flex items-center justify-center gap-2"
                    >
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessMessage;
