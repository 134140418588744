
import React from "react";

const SkipLink = () => {
  return (
    <a
      href="#main-content"
      className="sr-only focus:not-sr-only focus:absolute focus:top-4 focus:left-4 bg-bubble text-black/80 px-4 py-2 rounded-md focus:z-50 focus:outline-none focus:ring-2 focus:ring-bubble-dark"
    >
      Skip to main content
    </a>
  );
};

export default SkipLink;
