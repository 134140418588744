
import { Toaster } from "sonner";
import { ThemeProvider } from "next-themes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import Index from "./pages/Index";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFound";
import { checkSupabaseConnection } from "./utils/healthCheck";
import { toast } from "sonner";
import CookieConsent from "./components/CookieConsent";
import FloatingLanguageSelector from "./components/FloatingLanguageSelector";

function App() {
  useEffect(() => {
    const checkConnection = async () => {
      const isConnected = await checkSupabaseConnection();
      if (!isConnected) {
        toast.error("Unable to connect to the database. Please try again later.");
      }
    };
    
    checkConnection();
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <Toaster />
        <Router>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <FloatingLanguageSelector />
          <CookieConsent />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
