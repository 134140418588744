
import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowLeft } from "lucide-react";

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white py-20">
      <Helmet>
        <title>About Bubble Trouble - Our Mission & Values</title>
        <meta name="description" content="Learn about Bubble Trouble's mission to revolutionize digital social interactions through temporary, authentic spaces for genuine connections." />
        <meta name="keywords" content="about bubble trouble, social media mission, authentic connections, privacy first approach" />
        <meta property="og:title" content="About Bubble Trouble - Our Mission & Values" />
        <meta property="og:description" content="Learn about Bubble Trouble's mission to revolutionize digital social interactions through temporary, authentic spaces for genuine connections." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <div className="container mx-auto px-4">
        <button 
          onClick={() => navigate(-1)}
          className="flex items-center text-black/60 hover:text-black/80 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold gradient-text mb-8">About Bubble Trouble</h1>
          
          <div className="prose prose-lg">
            <p className="text-black/70 mb-6">
              Bubble Trouble is revolutionizing digital social interactions by creating 
              temporary, authentic spaces for genuine connections. We believe in the power 
              of ephemeral communication that mirrors real-life interactions.
            </p>

            <h2 className="text-2xl font-semibold mb-4 mt-8">Our Mission</h2>
            <p className="text-black/70 mb-6">
              To provide a platform where people can express themselves freely without 
              the burden of permanent digital footprints, fostering more genuine and 
              meaningful connections.
            </p>

            <h2 className="text-2xl font-semibold mb-4 mt-8">Our Values</h2>
            <ul className="list-disc pl-6 space-y-3 text-black/70">
              <li>Privacy-First Approach</li>
              <li>Authentic Interactions</li>
              <li>Temporal Communication</li>
              <li>User Empowerment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
