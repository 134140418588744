
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { MessageCircle, Shield, Clock, User, Bell } from "lucide-react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t, i18n } = useTranslation();
  // Force re-render when language changes
  const [, forceUpdate] = useState({});
  
  useEffect(() => {
    const handleLanguageChange = () => {
      console.log('FAQ component detected language change');
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    window.addEventListener('languageChangeComplete', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      window.removeEventListener('languageChangeComplete', handleLanguageChange);
    };
  }, []);
  
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  return (
    <section className="py-20 bg-gradient-to-b from-white via-bubble-soft/20 to-white" id="faq">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12 animate-fade-up">
            <span className="inline-block px-4 py-2 rounded-full bg-bubble-light text-black/80 text-sm font-medium mb-4 hover-scale">
              {t('faq.title', 'Common Questions')}
            </span>
            <h2 className="text-4xl font-bold text-black mb-4">
              {t('faq.heading', 'Learn How Bubble Trouble Works')}
            </h2>
            <p className="text-lg text-black/80">
              {t('faq.subheading', 'Discover how our platform changes social interactions')}
            </p>
          </div>

          <Accordion type="single" collapsible className="space-y-4">
            <AccordionItem value="item-1" className="border rounded-xl px-6 bg-white/95 shadow-sm hover:shadow-md transition-all duration-300">
              <AccordionTrigger className="text-lg font-medium text-black">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-bubble/10 flex items-center justify-center flex-shrink-0">
                    <MessageCircle className="w-4 h-4 text-bubble-dark" />
                  </div>
                  {t('faq.questions.special', 'What makes Bubble Trouble special?')}
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-black/90 pl-11">
                {t('faq.answers.special', 'Bubble Trouble transforms social media by using temporary Bubbles instead of permanent posts. These Bubbles are discussion spaces that change based on community interaction. When a Bubble gets many Reflects or reaches its time limit, it naturally disappears - just like real conversations.')}
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-2" className="border rounded-xl px-6 bg-white/95 shadow-sm hover:shadow-md transition-all duration-300">
              <AccordionTrigger className="text-lg font-medium text-black">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-bubble/10 flex items-center justify-center flex-shrink-0">
                    <User className="w-4 h-4 text-bubble-dark" />
                  </div>
                  {t('faq.questions.beta', 'Why join our beta community?')}
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-black/90 pl-11">
                {t('faq.answers.beta', 'As a beta member, you will be one of the first to try this new way of social interaction. You will help shape the future of online communication, get early access to new features, and join a community that values real connections.')}
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3" className="border rounded-xl px-6 bg-white/95 shadow-sm hover:shadow-md transition-all duration-300">
              <AccordionTrigger className="text-lg font-medium text-black">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-bubble/10 flex items-center justify-center flex-shrink-0">
                    <Shield className="w-4 h-4 text-bubble-dark" />
                  </div>
                  {t('faq.questions.privacy', 'How does privacy work?')}
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-black/90 pl-11">
                {t('faq.answers.privacy', 'Privacy is our main focus. Unlike other social media, Bubble Trouble messages disappear naturally. We do not track user behavior or sell data. Our Reflects system promotes quality interactions instead of quantity.')}
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-4" className="border rounded-xl px-6 bg-white/95 shadow-sm hover:shadow-md transition-all duration-300">
              <AccordionTrigger className="text-lg font-medium text-black">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-bubble/10 flex items-center justify-center flex-shrink-0">
                    <Clock className="w-4 h-4 text-bubble-dark" />
                  </div>
                  {t('faq.questions.when', 'When can I start using Bubble Trouble?')}
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-black/90 pl-11">
                {t('faq.answers.when', 'We are now in beta and welcoming new members to our growing community. Sign up now to get your spot and be part of the next generation of social interaction. Beta members get priority access and help create new features.')}
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-5" className="border rounded-xl px-6 bg-white/95 shadow-sm hover:shadow-md transition-all duration-300">
              <AccordionTrigger className="text-lg font-medium text-black">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-bubble/10 flex items-center justify-center flex-shrink-0">
                    <Bell className="w-4 h-4 text-bubble-dark" />
                  </div>
                  {t('faq.questions.after', 'What happens after I join?')}
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-black/90 pl-11">
                {t('faq.answers.after', 'After joining, you will get updates about our launch timeline and early access options. You will be among the first to create and join Bubbles, try the Reflects system, and help build a more authentic social media experience.')}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
