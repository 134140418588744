
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
  const { t, i18n } = useTranslation();
  const [showConsent, setShowConsent] = useState(false);
  const [, forceUpdate] = useState({});

  useEffect(() => {
    // Delay showing the cookie consent banner until after the page has loaded
    const timer = setTimeout(() => {
      const consent = localStorage.getItem('cookieConsent');
      if (!consent) {
        setShowConsent(true);
      }
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    const handleLanguageChange = () => {
      console.log('CookieConsent component detected language change');
      forceUpdate({});
    };
    
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    window.addEventListener('languageChangeComplete', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      window.removeEventListener('languageChangeComplete', handleLanguageChange);
    };
  }, []);
  
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-lg z-50">
      <div className="container mx-auto max-w-4xl flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-black/70">
          {t('cookies.message', 'We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies. Learn more in our')} {' '}
          <Link to="/privacy" className="text-bubble hover:underline">{t('nav.privacy', 'Privacy Policy')}</Link>.
        </div>
        <button
          onClick={handleAccept}
          className="whitespace-nowrap px-6 py-2 bg-bubble hover:bg-bubble-dark text-black/80 rounded-full transition-all duration-300 text-sm font-medium"
        >
          {t('cookies.accept', 'Accept & Close')}
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
