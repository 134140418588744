
import { useState, useEffect } from "react";
import { FormData, WaitlistEntry, ValidationErrors, REASON_MAX_LENGTH, SUBMISSION_COOLDOWN } from "./types";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const useRegistration = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    reason: "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [signupCount, setSignupCount] = useState<number>(0);
  const [registered, setRegistered] = useState<boolean>(
    localStorage.getItem("registered") === "true"
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastSubmissionTime, setLastSubmissionTime] = useState<number>(
    parseInt(localStorage.getItem("lastSubmissionTime") || "0")
  );

  // Analytics tracking setup
  useEffect(() => {
    const trackPageView = () => {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: 'Registration',
          page_location: window.location.href,
          page_path: '/registration'
        });
      }
    };

    trackPageView();
  }, []);

  // Scroll to top on successful submission
  useEffect(() => {
    if (submitted || registered) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [submitted, registered]);

  // Fetch initial count
  useEffect(() => {
    const fetchCount = async () => {
      try {
        console.log("Fetching registration count...");
        const { count, error } = await supabase
          .from('waitlist')
          .select('*', { count: 'exact', head: true });
        
        if (error) {
          console.error('Error fetching count:', error);
          return;
        }
        
        console.log('Current registration count:', count);
        setSignupCount(count || 0);
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    };

    fetchCount();
  }, []);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    // Handle character limit for reason field
    if (name === 'reason' && value.length > REASON_MAX_LENGTH) {
      return;
    }
    
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    
    // Clear errors when user starts typing
    if (errors[name as keyof ValidationErrors]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = (): ValidationErrors => {
    const validationErrors: ValidationErrors = {};
    
    // Name validation
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    } else if (formData.name.length < 2) {
      validationErrors.name = "Name must be at least 2 characters long";
    }

    // Email validation
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      validationErrors.email = "Please enter a valid email address";
    }

    // Reason length validation
    if (formData.reason.length > REASON_MAX_LENGTH) {
      validationErrors.reason = `Maximum ${REASON_MAX_LENGTH} characters allowed`;
    }

    // Rate limiting check
    const now = Date.now();
    if (now - lastSubmissionTime < SUBMISSION_COOLDOWN) {
      validationErrors.form = "Please wait a moment before submitting again";
    }

    return validationErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Starting form submission...");

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      try {
        console.log("Attempting to insert data into Supabase...");
        const { error } = await supabase
          .from('waitlist')
          .insert({
            name: formData.name,
            email: formData.email,
            reason: formData.reason || null,
            created_at: new Date().toISOString()
          });

        if (error) {
          console.error("Supabase error:", error);
          if (error.code === '23505') {
            toast.error("This email is already registered");
            setErrors((prev) => ({
              ...prev,
              email: "This email is already registered"
            }));
          } else {
            toast.error("Failed to register. Please try again.");
          }
          return;
        }

        // Track successful registration
        if (window.gtag) {
          window.gtag('event', 'registration', {
            'event_category': 'waitlist',
            'event_label': 'signup_complete'
          });
        }

        console.log("Registration successful!");
        setRegistered(true);
        setSubmitted(true);
        setLastSubmissionTime(Date.now());
        localStorage.setItem("lastSubmissionTime", Date.now().toString());
        
        // Update the signup count
        setSignupCount(prevCount => prevCount + 1);
        localStorage.setItem("registered", "true");
        
        toast.success("Successfully registered for the waitlist!");
        
        // Clear form
        setFormData({
          name: "",
          email: "",
          reason: "",
        });
      } catch (error) {
        console.error('Registration error:', error);
        toast.error("Failed to register. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      // Track validation errors
      if (window.gtag) {
        window.gtag('event', 'form_error', {
          'event_category': 'waitlist',
          'event_label': Object.keys(validationErrors).join(',')
        });
      }
      toast.error("Please fix the errors in the form");
    }
  };

  // Clean up function
  useEffect(() => {
    return () => {
      setSubmitted(false);
      setErrors({});
    };
  }, []);

  return {
    formData,
    errors,
    signupCount,
    registered,
    submitted,
    isLoading,
    handleChange,
    handleSubmit,
  };
};
