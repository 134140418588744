
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Initialize i18next
i18n
  // Load translations using http backend
  .use(Backend)
  // Detect user language
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
  // Initialize the configuration
  .init({
    // Default language
    fallbackLng: 'en',
    // Supported languages (added Spanish)
    supportedLngs: ['en', 'it', 'de', 'fr', 'es'],
    // Debug output in console - only in development
    debug: import.meta.env.DEV,
    
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    
    backend: {
      // Path to translation files
      loadPath: '/locales/{{lng}}/translation.json',
      // Explicitly set crossDomain to false for same-origin requests
      crossDomain: false,
      // Add request options for better handling
      requestOptions: {
        cache: 'no-cache'
      }
    },
    
    detection: {
      // Order of language detection methods - prioritize localStorage
      order: ['localStorage', 'navigator'],
      // Cache language in localStorage
      caches: ['localStorage']
    },
    
    react: {
      useSuspense: false, // Disable suspense to prevent issues during translation loading
      bindI18n: 'languageChanged loaded', // Ensure React components re-render when language changes or loads
    },
    
    // This ensures translations are loaded before the app renders
    initImmediate: false,
    
    // Add retry logic for failed loads
    load: 'currentOnly',
    
    // Better fallback mechanism
    partialBundledLanguages: true
  });

// Language change handler that safely notifies the app without forced remounting
i18n.on('languageChanged', (lng) => {
  console.log('Language changed to:', lng);
  
  // Update HTML lang attribute
  document.documentElement.lang = lng;
  
  // Store in localStorage
  localStorage.setItem('i18nextLng', lng);
  
  // Dispatch events for components to detect the change
  // These will trigger re-renders in components that are listening
  window.dispatchEvent(new Event('languageChanged'));
  
  // Wait for translations to be loaded, then signal the app to update
  setTimeout(() => {
    window.dispatchEvent(new Event('languageChangeComplete'));
  }, 100);
});

// Add specific handlers for loading success and failure
i18n.on('loaded', (loaded) => {
  console.log('i18n resources loaded:', loaded);
});

i18n.on('failedLoading', (lng, ns, msg) => {
  console.error(`i18n failed loading ${lng}/${ns}: ${msg}`);
  // If Spanish fails, try to load it again or fallback
  if (lng === 'es') {
    console.log('Retrying Spanish translation load...');
    i18n.reloadResources(['es'], ['translation']);
  }
});

export default i18n;
