
import React, { useState, useEffect } from "react";
import { MapPin, Sparkles, Trophy, Shield } from "lucide-react";
import { useTranslation } from "react-i18next";
import ScrollReveal from "./ScrollReveal";

const FeatureItem = ({
  icon: Icon,
  title,
  description,
  index,
}: {
  icon: React.ComponentType<any>;
  title: string;
  description: string;
  index: number;
}) => (
  <ScrollReveal 
    variant="fade-up" 
    delay={100 * index} 
    className="flex flex-col items-center p-4 md:p-8 rounded-2xl bg-white/80 backdrop-blur-lg border border-bubble/20 shadow-xl hover:shadow-2xl hover:scale-105 transition-all duration-300"
  >
    <div className="w-12 h-12 md:w-16 md:h-16 rounded-full bg-bubble/10 flex items-center justify-center mb-4 md:mb-6">
      <Icon className="w-6 h-6 md:w-8 md:h-8 text-bubble-dark" />
    </div>
    <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-3 gradient-text">{title}</h3>
    <p className="text-sm md:text-base text-black/60 text-center leading-relaxed">{description}</p>
  </ScrollReveal>
);

const Features = () => {
  const { t, i18n } = useTranslation();
  // Force re-render when language changes
  const [, forceUpdate] = useState({});
  
  useEffect(() => {
    // This function will be called when the language changes
    const handleLanguageChange = () => {
      console.log('Features component detected language change:', i18n.language);
      // Force component to re-render
      forceUpdate({});
    };
    
    // Listen to language change events
    window.addEventListener('languageChanged', handleLanguageChange);
    document.addEventListener('i18nextLanguageChanged', handleLanguageChange);
    
    // Set up listener for i18next initialization
    if (!i18n.isInitialized) {
      const initialized = () => {
        console.log('i18next initialized in Features component');
        forceUpdate({});
      };
      i18n.on('initialized', initialized);
      return () => {
        i18n.off('initialized', initialized);
        window.removeEventListener('languageChanged', handleLanguageChange);
        document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
      };
    }
    
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
      document.removeEventListener('i18nextLanguageChanged', handleLanguageChange);
    };
  }, [i18n]);

  // Add direct listener for i18n language change
  useEffect(() => {
    forceUpdate({});
  }, [i18n.language]);

  return (
    <section id="features" className="py-8 md:py-16">
      <div className="container mx-auto px-4 text-center">
        <ScrollReveal variant="fade-up">
          <div className="mb-6 md:mb-8 animate-fade-up relative group">
            <div className="absolute inset-0 bg-bubble-light/30 rounded-full filter blur-xl group-hover:blur-2xl transform group-hover:scale-110 transition-all duration-700"></div>
            <img
              src="/lovable-uploads/3630a64e-6598-4188-85c2-1c85d00e59a6.png"
              alt="Bubble Trouble Logo"
              className="w-16 h-16 md:w-24 md:h-24 mx-auto object-contain relative z-10 transform transition-all duration-500 
                       hover:scale-105 hover:rotate-3 active:scale-95
                       drop-shadow-[0_0_15px_rgba(255,215,0,0.3)]
                       hover:drop-shadow-[0_0_25px_rgba(255,215,0,0.5)]"
            />
          </div>
        </ScrollReveal>
        
        <ScrollReveal variant="fade-up" delay={100}>
          <span className="inline-block px-4 py-2 md:px-6 md:py-3 rounded-full bg-bubble-light text-black/70 text-xs md:text-sm font-medium mb-3 md:mb-4 animate-fade-up hover-scale">
            {t('features.title')}
          </span>
        </ScrollReveal>
        
        <ScrollReveal variant="fade-up" delay={200}>
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-3 md:mb-4 gradient-text animate-fade-up hover-scale">
            {t('features.heading')}
          </h2>
        </ScrollReveal>
        
        <ScrollReveal variant="fade-up" delay={300}>
          <p className="text-base md:text-lg text-black/60 mb-8 md:mb-12 max-w-2xl mx-auto animate-fade-up">
            {t('features.subheading')}
          </p>
        </ScrollReveal>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 max-w-6xl mx-auto">
          <FeatureItem
            icon={MapPin}
            title={t('features.bubbleRadar.title')}
            description={t('features.bubbleRadar.description')}
            index={0}
          />
          <FeatureItem
            icon={Sparkles}
            title={t('features.reflectsSystem.title')}
            description={t('features.reflectsSystem.description')}
            index={1}
          />
          <FeatureItem
            icon={Trophy}
            title={t('features.dynamicEngagement.title')}
            description={t('features.dynamicEngagement.description')}
            index={2}
          />
          <FeatureItem
            icon={Shield}
            title={t('features.digitalFreedom.title')}
            description={t('features.digitalFreedom.description')}
            index={3}
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
