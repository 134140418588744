
import React, { useEffect, useRef, ReactNode } from 'react';

type AnimationVariant = 
  | 'fade-up'
  | 'fade-down'
  | 'fade-left'
  | 'fade-right'
  | 'zoom-in'
  | 'zoom-out';

type ScrollRevealProps = {
  children: ReactNode;
  variant?: AnimationVariant;
  delay?: number;
  duration?: number;
  threshold?: number;
  className?: string;
  once?: boolean;
};

const ScrollReveal: React.FC<ScrollRevealProps> = ({
  children,
  variant = 'fade-up',
  delay = 0,
  duration = 700,
  threshold = 0.1,
  className = '',
  once = true,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const renderedRef = useRef(false);

  useEffect(() => {
    const element = elementRef.current;
    
    if (!element) return;
    
    // Initially hide the element
    element.style.opacity = '0';
    element.style.transform = getInitialTransform(variant);
    element.style.transition = `opacity ${duration}ms ease-out, transform ${duration}ms ease-out`;
    element.style.transitionDelay = `${delay}ms`;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the element is in view
          if (entry.isIntersecting) {
            // Show the element
            element.style.opacity = '1';
            element.style.transform = 'translate3d(0, 0, 0) scale(1)';
            
            // If once is true, unobserve after animation
            if (once) {
              observer.unobserve(element);
              renderedRef.current = true;
            }
          } else if (!once && renderedRef.current) {
            // If not in view and we want to re-animate
            element.style.opacity = '0';
            element.style.transform = getInitialTransform(variant);
          }
        });
      },
      { threshold }
    );
    
    observer.observe(element);
    
    return () => {
      observer.disconnect();
    };
  }, [variant, delay, duration, threshold, once]);
  
  const getInitialTransform = (variant: AnimationVariant): string => {
    switch (variant) {
      case 'fade-up':
        return 'translate3d(0, 40px, 0)';
      case 'fade-down':
        return 'translate3d(0, -40px, 0)';
      case 'fade-left':
        return 'translate3d(40px, 0, 0)';
      case 'fade-right':
        return 'translate3d(-40px, 0, 0)';
      case 'zoom-in':
        return 'scale(0.9)';
      case 'zoom-out':
        return 'scale(1.1)';
      default:
        return 'translate3d(0, 0, 0)';
    }
  };
  
  return (
    <div ref={elementRef} className={className}>
      {children}
    </div>
  );
};

export default ScrollReveal;
